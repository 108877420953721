@import "assets/variables";
@import "assets/media-queries";

.case-overview {
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  font-size: 18px;
  padding: 0 8px;
  justify-content: space-between;
  
  @include desktop {
    padding: 0 200px;
  }

  @media screen and (min-width: $desktop-width) and (max-width: $max-width) {
    padding: 0 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1140px) {
    padding: 0;
  }
  
  @include tablet {
    flex-direction: row;
    margin-top: 110px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: -0.36px;
  }

  &__left {
    width: 100%;
    
    @include tablet {
      margin-right: 120px;
      width: 381px;
    }

    & > h2 {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 16px;
    }
  }

  &__right {
    width: 100%;
    line-height: 26px;

    @include tablet {
      width: 280px;
    }

    p {
      font-size: 15px;
      line-height: 26px;
      letter-spacing: -0.5px;
    }

    &__item {
      & > p {
        margin-bottom: 10px;
        font-size: 15px;
      }
    }

    &__stack {
      margin-top: 24px;

      & > p {
        margin: 0;
        font-size: 15px;
        line-height: 10px;
      }
      & > span {
        font-size: 12px;
        opacity: 0.5;
      }
    }

    &__apps {
      margin-top: 24px;

      & > a {
        &:last-child {
          margin-right: 0;
        }

        margin-right: 24px;
        font-size: 18px;
      }
    }
  }
}