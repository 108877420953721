@import "./variables";
@import "./media-queries";

body, p, span, h1, h2, h3 {
  color: $primary-text;
  font-weight: normal;
}

a {
  color: $primary-text;
  text-decoration: none;

  position: relative;
  display: inline-table;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $primary-text;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &:active,
  &:hover {  
    &:after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

h1 {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -1.39px;
  margin: 0 0 16px 0;
  
  @include tablet {
    font-size: 60px;
    margin: 0 0 24px 0;
  }
}

h2 {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

p {
  margin: 0 0 36px 0;
}