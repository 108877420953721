@import "assets/variables";

.button {
  padding: 11px 22px;
  border: 1px solid;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  transition: filter 0.2s, background-color 0.2s, color 0.2s, transform 0.2s;
  width: fit-content;

  &:hover {
    cursor: pointer;
    transform: translateY(-5%);
  }

  &.--default {
    background-color: $primary-button;
    color: $primary-bg;
    border-color: $primary-button;

    &:hover {
      filter: opacity(0.8);
    }
  }

  &.--outline {
    background-color: transparent;
    color: $dark-bg;
    border-color: $dark-bg;
    position: relative;
    overflow: hidden;

    &:hover {
      color: $light-text;
      transform: translateY(0);

      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $dark-bg;
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 0.3s, transform 0.2s;
      z-index: -1;
    }
  }
};
