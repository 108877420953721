@import 'assets/media-queries';

.about {
  padding-top: 47px;

  p {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .color-block {
    height: 375px;

    @include desktop {
      flex: 1 1 !important;
      height: 580px;
    }
  }

  img {
    width: 100%;
    height: 375px;
    object-fit: cover;

    @include tablet {
      height: auto;
    }
  
    @include desktop {
      height: 580px;
    }
  }

  &__social {
    padding-top: 28px;

    @include desktop {
      padding-top: 22px;
    }

    &__cv {
      display: flex;
      justify-content: flex-start;
      margin: 36px 0 64px 0;

      @include desktop {
        justify-content: flex-end;
        margin: 0;
      }

      a {
        &:after {
          bottom: 6px;
        }
      }
    }

    .social {
      display: flex;
      justify-content: center;

      @include desktop {
        justify-content: start;
      }

      .link {
        margin-right: 56px;

        @media screen and (min-width: $desktop-width) and (max-width: $max-width) {
          margin-right: 36px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .link {
      text-align: start;

      @include desktop {
        text-align: end;;
      }
    }
  }

  &__column {
    margin-bottom: 70px;

    @include desktop {
      margin-bottom: 198px;
    }

    & > div:nth-child(2) {
      margin-top: 48px;

      @include desktop {
        margin-top: 0;
      }
    }
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -80px;
    padding: 64px 0 88px 0;

    @include desktop {
      margin-top: -128px;
      padding: 96px 0 204px 0;
      justify-content: center;
    }

    &__item {
      margin: 80px 4% 0 4%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tablet {
        margin: 128px 5.2% 0 5.2%;
      }
  
      img {
        width: 118px;
        height: 32px;
        object-fit: contain;
  
        @include desktop {
          width: 165px;
          height: 50px;
        }
      }

      p {
        margin-top: 28px;
        font-weight: 500;
        max-width: 120px;

        text-align: center;
        font-size: 18px;

        @include tablet {
          max-width: 182px;
          font-size: 21px;
        }
      }
    }
  }

  .education {
    .about__section__item {
      margin: 64px 4% 0 4%;

      @include tablet {
        margin: 128px 4% 0 4%;
      }
    }

    img {
      width: 95px;
      height: 95px;
      object-fit: contain;

      @include desktop {
        width: 109px;
        height: 109px;
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    margin-bottom: 156px;
    position: relative;

    button {
      font-size: 20px;
      padding: 16px 32px;
    }

    p {
      width: 185px;
      font-size: 16px;
      margin-top: 8px;
      position: absolute;
      top: 60px;
      opacity: 0;
      transform: translateY(50%);
      transition: all 0.2s;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}