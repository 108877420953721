@import 'assets/media-queries';

.columns {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;

    .col + .col {
      margin-left: var(--gap-value);
    }
  }

  &.--reverse {
    flex-direction: column;

    @include desktop {
      flex-direction: row-reverse;

      .col + .col {
        margin-right: var(--gap-value);
        margin-left: 0;

      }
    }
  }

  .col {
    flex: 1;
  }
}