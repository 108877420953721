@import "assets/media-queries";

.case-section {
  padding-top: 40px;
  font-size: 18px;

  h2 {
    font-size: 24px;
  }

  li {
    list-style-type: none;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
  }

  &__content {
    padding: 0 8px;

    @include desktop {
      padding: 0 200px;
    }

    @media screen and (min-width: $desktop-width) and (max-width: $max-width) {
      padding: 0 100px;
    }
  
    @media screen and (min-width: 1024px) and (max-width: 1140px) {
      padding: 0;
    }
  }

  &__images {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 88px;

    @include tablet {
      justify-content: space-evenly;
    }

    &.--padding {
      padding: 0 8px;

      @include tablet {
        padding: 0 var(--padding-value, 200);
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      max-width: calc(100vw - 32px);

      & > img {
        object-fit: contain;
      }

      &__label {
        margin-bottom: 30px;
        margin-top: 8px;

        @include tablet {
          margin-bottom: 60px;
          margin-top: 24px;
        }

        & > span {
          font-size: 14px;
        }
  
        & > p {
          margin: 0;
          opacity: 0.55;
          font-size: 14px;
        }
      }
    }
  }
}