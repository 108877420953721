@import "assets/variables";
@import "assets/media-queries";

.footer {
  background-color: beige;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 93px 36px;
  background-color: $secondary-bg;
  text-align: center;

  & > span {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  & > p {
    margin-bottom: 16px;
    letter-spacing: -0.4;
    font-size: 16px;
  }

  &__links {
    margin-bottom: 24px;

    @include tablet {
      flex-direction: row;
      width: 422px;
      margin-bottom: 32px;
    }

    & > a {
      margin-bottom: 8px;
    }

    align-items: center;
    width: fit-content;
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__social {
    @include tablet {
      width: 316px;
    }

    width: 260px;
    display: flex;
    justify-content: space-between;
  }
}