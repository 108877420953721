@import "assets/variables";
@import "assets/media-queries";

.case-hero {
  background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  width: 100vw;
  left: 0;
  margin-left: -16px;
  min-height: 592px;
  margin-top: 48px;
  
  @include tablet {
    margin-left: -65px;
    min-height: 558px;
    margin-top: 0px;
  }

  @media (min-width: $max-width) {
    margin-left: calc((-1 * (100vw - 1440px + 260px)) / 2);
  }
  
  @media screen and (min-width: $desktop-width) and (max-width: $max-width) {
    margin-left: -130px;
  }
  
  &__wrapper {
    width: 100%;
    max-width: $max-width;
    padding: 80px 0 0 0;
    overflow: hidden;
  
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 0 130px;
      margin-left: -65px;
    }

    & > img {
      width: 100%;

      @include tablet {
        height: 558px;
        width: auto;
      }
    }

    &__left {
      padding: 0 24px;

      & > img {
        height: 50px;
        margin-bottom: 32px;
        max-width: 80%;

        padding: 0 8px;

        @include tablet {
          padding: 0;
        }
      }
    
      & > p {
        width: 350px;
        font-size: 18px;
        padding: 0 8px;
        margin-bottom: 80px;

        @include tablet {
          padding: 0;
          margin-bottom: 36px;
        }
      }
    }
  }
}