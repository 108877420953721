@import 'assets/variables';
@import 'assets/media-queries';

.link {
  font-size: 19.4px;
  transition: color 0.2s;
  font-weight: 500;
  cursor: pointer;
  overflow: visible;
  width: fit-content;

  &:after {
    content: none;
    width: 0;
  }

  &:visited {
    color: inherit;
  }

  &.--light {
    color: $link-text;
    text-decoration: none;
    overflow: hidden;
    padding-bottom: 10px;

    &:after {
      content: '';
      top: 26px;
      width: 100%;
      background-color: $link-text;
    }  
  }

  &.--underline,
  &.--button {
    overflow: hidden;
    font-weight: bold;
    color: $primary-text;
    text-decoration: none;
    text-align: center;

    letter-spacing: -0.5px;

    &:after {
      content: '';
      width: 100%;
    }
  }

  &.--underline {
    font-size: 18px;
    letter-spacing: 0;
  }

  &.--scale {
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }

    &:after {
      height: 0;
    }
  }
}