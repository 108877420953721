@import "assets/media-queries";

.feature {
  margin-top: 32px;
  margin-right: 40px !important;
  width: 100%;
  letter-spacing: -0.42px;

  @include tablet {
    margin: 0;
    width: 284px;
  }

  & > h4 {
    font-size: 18px;
    margin: 24px 0 16px 0;
  }

  & > p {
    font-size: 14px;
    margin-bottom: 0;
  }

  & > img {
    width: 57px;
    height: 57px;
  }
}