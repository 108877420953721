@import "assets/variables";
@import "assets/media-queries";

.home {
  & > .home-case {
    border-bottom: 1px solid $black;

    &:last-of-type {
      border: none;
    }

    @include tablet {
      border: none;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 50px;
  z-index: 10000;
  border: none;
  border-radius: 10px;
  width: 560px;
  height: 500px;
}

.modal h1{
  font-size: 40px;
  color: #000;
}

.modal h4{
  font-size: 26px;
  color: #333333;
  margin: 0;
}

.modal .txt{
  color: #666;
  margin-top: 4px;
}

.modal .txt2{
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .modal {
    width: 90%;
    padding: 30px;
    height: auto;
  }
}

.modal:focus-visible{
  outline: none;
}

.input-pass{
  width: 100%;
  float: left;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #999595;
  padding: 5px 10px;
  font-size: 15px;
}

.send_btn{
  width: 180px;
  margin: auto;
  display: block;
  background-color: rgba(0, 0, 0, 1);
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .send_btn{
    width: 200px;
  }
}

.disabled{
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
}
.error{
  display: none;
  color: #CF0000;
}

.error img{
  width: 10px;
}

.cnt_error{
  width: 100%;
    height: 35px;
    display: block;
    float: left;
    margin: 6px 0;
}