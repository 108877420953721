@import "assets/styles.scss";
@import "assets/media-queries.scss";

body {
  font-family: 'DM Sans', sans-serif;
  margin: 0;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}

*, *::before, *::after {
	box-sizing: border-box;
}