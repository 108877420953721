@import "assets/media-queries.scss";

.nav {
  padding: 32px 16px;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: auto;

  @include tablet {
    padding: 48px 72px;
  }
  
  &__menu {
    display: none;

    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__success {
        margin: 0;
        font-size: 10px;
      }

      &__links {
        display: flex;
        > div {
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          position: relative;
          cursor: pointer;

          > span {
            position: absolute;
            top: 30px;
            transform: translateY(-10px);
            transition: all 0.2s;
            opacity: 0;
            cursor: default;

            &.visible {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }

      }
    }

    &__success {
      width: 183px;

      @include tablet {
        width: 76px;
      }
    }

    &--mobile {
      @include tablet {
        display: none;
      }

      > button {
        width: 42px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &:active,
        &:hover {
          > span {
            background-color: $link-text;
          }
        }

        > span {
          width: 100%;
          height: 2px;
          background-color: $dark-bg;
          border-radius: 6px;
          transition: background-color 0.2s;
          border: solid 3px #011733;

          &:nth-child(2) {
            width: 70%;
            margin-top: 6px;
          }
        }
      }

      &__overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $dark-bg;
        color: $light-text;
        padding: 32px 16px;
        flex-direction: column;
        transform: translateX(100%);
        transition: transform 0.2s ease-in-out;
        display: flex;
        z-index: 10;

        &.--open {
          transform: translateX(0);
          opacity: 1;
        }

        svg {
          fill: white;
        }

        &__header {
          display: flex;
          justify-content: space-between;

          .link {
            color: $light-text;
          }
        }

        &__links {
          flex: 1; 
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: fit-content;

          > div {
            display: flex;
            flex-direction: column;

            > span {
              color: $light-text;
              font-size: 16px;
              font-weight: 500;
              opacity: 0;
              transform: translateY(-10px);
              transition: all 0.2s;

              &.visible {
                transform: translateY(0);
                opacity: 1;
              }
            }
  
            .link {
              color: $light-text;
              margin: 4px 0;
              font-size: 61px;
              font-weight: bold;
              
              &:after {
                display: none;
              }
  
              &:hover {
                color: $light-text;
              }

              &:active {
                color: $link-text;
              }
            }
          }
        }
      }
    }
  }
}