// Font colors
$primary-text: #011733;
$secondary-text: #008950;
$light-text: #ffffff;
$link-text: #0047a1;

// Bg Colors
$primary-bg: #ffffff;
$secondary-bg: #fbfbfb;
$dark-bg: #011733;
$black: #000000;

// Button Colors
$primary-button: #f46709;
$primary-hover: #fb8331;
$secondary-button: transparent;
$secondary-hover: #011733;

// Queries
$tablet-width: 768px;
$desktop-width: 1024px;

// Size
$max-width: 1440px;